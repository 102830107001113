html,
body {
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.no-scroll {
  overflow: hidden;
}

.root {
  height: 100%;
}

@media (prefers-color-scheme: dark) {
  .root {
    background-color: #1a1a1a;
  }

  body {
    background-color: #1a1a1a;
  }
}
